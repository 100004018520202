<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="localeText.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <h1 style="margin-bottom: 24px">
            {{ localeText.title }}
          </h1>
          <div class="wrapper-comparison">
            <div class="header-tabs__comparison">
              <span class="header-tabs__comparison-label">
                {{ localeText.compareShow }}
              </span>
              <el-tabs
                v-model="activeName"
                class="tabs-main-default"
                @tab-click="handleClick"
              >
                <el-tab-pane
                  :label="localeText.allCharacteristics"
                  name="first"
                >
                  <wrapper
                    v-if="programs.length"
                    scroll-horizontal
                  >
                    <el-row class="wrapper-table__comparison">
                      <comparison-item
                        v-for="program in programs"
                        :localization="localization"
                        :key="program.id"
                        :program="program"
                      />
                    </el-row>
                  </wrapper>
                  <empty-wrapper v-else>
                    <empty-data-message :text="localeHtml.emptyComparison" />
                  </empty-wrapper>
                </el-tab-pane>

                <el-tab-pane
                  :label="localeText.onlyDifferent"
                  name="second"
                >
                  <wrapper
                    v-if="programs.length"
                    scroll-horizontal
                  >
                    <el-row class="wrapper-table__comparison">
                      <comparison-item
                        v-for="program in programs"
                        :key="program.id"
                        :program="program"
                        :localization="localization"
                        :show-props="differentProps"
                      />
                    </el-row>
                  </wrapper>
                  <empty-wrapper v-else>
                    <empty-data-message :text="localeHtml.emptyComparison" />
                  </empty-wrapper>
                </el-tab-pane>
              </el-tabs>
              <show-more-program v-if="programs.length" />
            </div>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import MenuWrapper from '@/components/menuWrapper/index.vue';
import PageFooter from '@/components/PageFooter.vue';
import {mapGetters, mapState} from 'vuex';
import ComparisonItem from '@/components/componentComparison/comparisonItem.vue';
import Wrapper from '@/components/wrappers/Wrapper.vue';
import ErrorPagePublic from '@/views/404/index.vue';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import ShowMoreProgram from '@/components/programs/ShowMoreProgram.vue';
import localization from '@/i18n/localization';

export default {
  name: 'ComparisonPage',
  components: {
    ShowMoreProgram,
    ErrorPagePublic,
    Wrapper,
    ComparisonItem,
    PageFooter,
    MenuWrapper,
    EmptyDataMessage,
    EmptyWrapper
  },
  data() {
    return {
      title: 'Сравнение курсов',
      name: 'Сравнение',
      pageClass: 'comparison-page',
      activeName: 'first',
      localization: localization('dpo.programs.comparison')
    };
  },
  computed: {
    ...mapState({
      menuActive: state => state.menuActive,
      programs: state => state.compares.items
    }),
    ...mapGetters(['isPublicUser']),
    localeText() {
      return {
        title: this.localization.widgetCaption('comparisonTitle'),
        onlyDifferent: this.localization.widgetCaption('onlyDifferent'),
        allCharacteristics:
          this.localization.widgetCaption('allCharacteristics'),
        compareShow: this.localization.widgetCaption('compareShow')
      };
    },
    localeHtml() {
      return {
        emptyComparison: this.localization.widgetCaption('emptyComparison')
      };
    },
    differentProps() {
      let props = [
        'department',
        'price',
        'dateStart',
        'final_document',
        'edu_format',
        'duration',
        'hours',
        'children_count'
      ];

      let showProps = ['title'];

      if (this.programs.length < 2) {
        showProps.push(...props);
      }

      props.map(prop => {
        for (let i = 0; i < this.programs.length; i++) {
          if (
            this.programs.find(
              program => program[prop] !== this.programs[i][prop]
            )
          ) {
            showProps.push(prop);
            break;
          }
        }
      });

      return showProps;
    }
  },
  async created() {
    await this.$store.dispatch('compares/fetch');
  },
  methods: {
    handleClick() {}
  }
};
</script>

<style lang="scss">
.wrapper-table__comparison {
  background-color: #fff;
  padding: 0 24px;
}

.header-tabs__comparison {
  position: relative;
}
.header-tabs__comparison-label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--font-color-alt);
}

.tabs-main-default.el-tabs {
  .el-tabs__nav-scroll {
    padding-left: 103px;
    position: relative;
  }
  .el-tabs__header {
    margin: 0 0 28px;
  }
  .el-tabs__nav-wrap::after {
    content: unset;
  }
  .el-tabs__nav {
    float: none;
    display: flex;
    flex-wrap: wrap;
  }
  .el-tabs__active-bar {
    background-color: var(--dark-blue);
  }
  .el-tabs__item {
    height: 25px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--font-color);
    &:focus-visible {
      outline: none;
    }
    &.is-active {
      color: var(--dark-blue);
    }
    &.is-top {
      padding: 0 16px;
      &:nth-child(2) {
        padding-left: 0;
      }
      &:last-child {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .comparison-page .tabs-main-default.el-tabs .el-tabs__item.is-top {
    padding: 0;
  }
  .tabs-main-default.el-tabs .el-tabs__nav {
    gap: 10px;
  }
  .comparison-page {
    .el-tabs__nav-scroll {
      padding-bottom: 2px;
    }
    .tabs-main-default {
      &.el-tabs {
        .el-tabs__active-bar {
          display: none !important;
        }
        .el-tabs__item {
          transition: all 200ms ease;
          &.is-active {
            box-shadow: 0 2px 0 0 var(--dark-blue);
          }
        }
      }
    }
  }
}
// tabs style
</style>
