export default {
  namespaced: true,

  state: {
    shouldRefresh: false
  },

  mutations: {
    refreshComments(state, refresh) {
      state.shouldRefresh = refresh;
    }
  },
  actions: {
    setShouldRefresh(context, value) {
      context.commit('refreshComments', value);
    }
  }
};
