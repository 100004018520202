import Vue from 'vue';
import Vuex from 'vuex';
import Axios from '@/utils/Elk-Axios';
import localStorageService from '@/utils/localStorageService';
import router from '../router';
import setNotification from '@/utils/setNotification';
import I18N from '@/i18n';
import {getUiItemCaption} from '@/i18n/utils';

const AppVersion = require('/package.json').version;
import {
  setUser,
  clearState,
  setNotification as setNotificationMutationName,
  deleteAllNotification,
  setAvatar,
  SET_ADFS_ENTRY_POINT
} from './mutationsName';
import {
  getUser,
  setNewRoutes,
  GET_ADDITIONAL_PAGE_TREE_ITEMS,
  GET_ADFS_ENTRY_POINT,
  GET_LEARNERS
} from './actionsName';
import pageLoader from '@/utils/pageLoader';
import elkMyServices from './modules/elkMyServices';
import elkHomeService from './modules/elkHomeService';
import elkMyContacts from './modules/elkMyContacts';
import developmentConstants from './modules/developmentConstants';
import elkLocale from './modules/locale';
import {PUBLIC_TOKEN} from '@/utils/constants';
import notificationCounter from './modules/notificationCounter';
import postgraduate from './modules/postgraduate';
import dictionaries from './modules/dictionaries';
import validationPeriods from './modules/validationPeriods';
import checkNotification from './plugins/checkNotification';
import cartModule from './modules/cart/cart';
import compares from './modules/compares/compares';
import servicesModule from './modules/service/service';
import scheduleModule from './modules/schedule/schedule';
import programs from './modules/programs/programs';
import refDepartments from './modules/references/departments';
import refTrainingAreas from './modules/references/trainingAreas';
import refFinalDocumentTypes from './modules/references/finalDocumentTypes';
import refDurationTypes from './modules/references/durationTypes';
import refDurationHours from './modules/references/durationHours';
import refForWhom from './modules/references/forWhom';
import refEduFormats from './modules/references/eduFormat';
import refLanguages from './modules/references/languages';
import refCampuses from './modules/references/campuses';
import refProgramTypes from './modules/references/programTypes';
import profileFillCheck from './modules/profileFillCheck/profileFillCheck';
import profileData from './modules/profileData/profileData';
import paymentOptions from './modules/references/paymentOptions';
import countries from './modules/references/countries';
import representProfileLegalStore from './modules/represent/representProfileLegalStore';
import representProfileStore from './modules/represent/representProfileStore';
import fieldRules from './modules/fieldRules';
import studentService from './modules/studentService';

import {
  GET_USER as GET_USER_API_METHOD,
  GET_ADDITIONAL_PAGE_TREE,
  GET_ADFS_ENTRY_POINT as GET_ADFS_ENTRY_POINT_API_METHOD,
  KEDO_GET_STATUS_SEV_KEDO,
  COMPARISON_PROGRAM_ADD,
  COMPARISON_PROGRAM_REMOVE,
  FAVORITE_PROGRAM_ADD,
  FAVORITE_PROGRAM_REMOVE,
  //FAVORITE_PROGRAM_CLEAR,
  GET_RECOMMENDED_PROGRAMS
} from '@/api';
import elkFooter from './modules/elkFooter';
import elkBreadcrumbs from './modules/elkBreadcrumbs';
import setI18nLanguage from '@/i18n/setI18nLanguage';
import {
  REGISTRATION_ROUTE,
  HOME_ROUTE,
  USER_SUGGETSTION_ROUTE
} from '@/router/routes';
import {setActivityRole} from './api';
import {getFilteredPageTreeByBlockWidgetSignature} from './utils';
import {isValidUrl} from '@/utils/types';
import {prepareProgramCardRecommendedView} from '@/utils/prepareProgramCardData';
//import {commit} from 'lodash/seq';

const NotifPlugin = checkNotification(60 * 1000);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuActive: false,
    width: window.innerWidth,
    user: {
      pageTree: []
    },
    isLoadUser: true,
    notifications: [],
    isSettingOpen: false,
    adfsEntryPoint: null,
    version: AppVersion,
    statusSevKedo: {
      isSev: false,
      isKedo: false
    },
    dpoRole: false,
    representRole: false,
    dpoNavLinks: [],
    offeredPrograms: [],
    consolidateNavLinks: [],
    countFavorites: 0,
    countComparison: 0,
    widgets: new Map(),
    keyCloakRealmName: 'hse',
    mainPageNotAvailable: false,
    isADFS: false,
    isTouchDevice: false
  },
  insert: ({$route}) => {
    $route;
  },
  getters: {
    urlPrfix(state) {
      return state.user.s3_url;
    },
    userRoles(state) {
      return state.user.roles;
    },
    userDpo(state) {
      return state.dpoRole;
    },
    userRepresent(state) {
      return state.representRole;
    },
    dpoNavLinks(state) {
      return state.dpoNavLinks;
    },
    userHasAccessSuggestions(state) {
      const suggestionPage = state.user.pageTree.find(
        page => page.url === USER_SUGGETSTION_ROUTE
      );
      return Boolean(suggestionPage?.widgets.length);
    },
    consolidateMenuLinks(state) {
      const result = state.consolidateNavLinks.length
        ? state.consolidateNavLinks
        : state.user.pageTree.filter(link => link.is_in_menu);
      return result;
    },
    getLinkUrlBySignature(state) {
      return signature =>
        state.user.pageTree.find(e => e.signature === signature)?.url ?? '/';
    },
    isPostgraduate(state, getters) {
      return getters.userRoles.includes('postgraduate');
    },
    pageTreeForMenu(state) {
      return state.user.pageTree.filter(link => link.is_in_menu); // menuItems
    },
    pageTreeForNewMenu(state) {
      return state.user.pageTree.filter(link => link.is_new_menu);
    },
    isMobile(state) {
      return state.width < 768;
    },
    isTablet(state) {
      return state.width < 1440 && state.width >= 768;
    },
    isSmallScreenPC(state) {
      return state.width < 1024 && state.width >= 768;
    },
    isLoadUser(state) {
      return state.isLoadUser;
    },
    isAuth(state) {
      return !state.user.roles?.includes('public') ?? false;
    },
    isCanOrder(state, rootGetters) {
      return rootGetters['profileFillCheck/isProfileCanOrder'];
    },
    isForeigner(state, rootGetters) {
      return rootGetters['profileFillCheck/isForeigner'];
    },
    lang(state) {
      return state.user.lang;
    },
    email(state) {
      return state.user.email;
    },
    phone(state) {
      return state.user.phone_internal ?? '';
    },
    getFullName(state) {
      let fullName = '';

      if (state.user.last_name && state.user.first_name) {
        fullName = state.user.last_name + ' ' + state.user.first_name;
      } else return '-';

      if (state.user.middle_name) {
        fullName += ' ' + state.user.middle_name;
      }

      return fullName;
    },

    userCredentials(state, getters) {
      return {
        fullName: getters.getFullName,
        gender_code: state.user.gender_code,
        phone: state.user.phone_internal,
        tab_num: state.user.external_id
      };
    },

    getAdfsUrl(state, getters) {
      //TODO: remove ADFS from the project
      if (state.isADFS) {
        return (
          `${state.adfsEntryPoint}/adfs/oauth2/authorize/?client_id=${process.env.VUE_APP_DEFAULT_ADFS_CLIENT_ID}&redirect_uri=` +
          window.location.origin +
          `${process.env.VUE_APP_ADFS_REDIRECT_URL}&response_mode=form_post&response_type=code`
        );
      }
      const currentLang = getters['locale/getLocaleName'] || 'ru';
      return (
        `${state.adfsEntryPoint}/realms/${state.keyCloakRealmName}/protocol/openid-connect/auth?client_id=${process.env.VUE_APP_DEFAULT_KEYCLOAK_CLIENT_ID}&redirect_uri=` +
        window.location.origin +
        `${process.env.VUE_APP_KEYCLOAK_REDIRECT_URL}/&response_type=code&scope=openid&ui_locales=${currentLang}`
      );
    },
    getPublicAdfsUrl(state) {
      //TODO: remove ADFS from the project
      if (state.isADFS) {
        return (
          `${state.adfsEntryPoint}/adfs/oauth2/authorize/?client_id=${process.env.VUE_APP_DEFAULT_ADFS_CLIENT_ID}&redirect_uri=` +
          window.location.origin +
          `${process.env.VUE_APP_ADFS_REDIRECT_URL}&response_mode=form_post&response_type=code&prompt=none`
        );
      }
      return (
        `${state.adfsEntryPoint}/realms/${state.keyCloakRealmName}/protocol/openid-connect/auth?client_id=${process.env.VUE_APP_DEFAULT_KEYCLOAK_CLIENT_ID}&redirect_uri=` +
        window.location.origin +
        `${process.env.VUE_APP_KEYCLOAK_REDIRECT_URL}/&response_type=code&scope=openid&prompt=none`
      );
    },
    getAdfsLogOut(state) {
      if (state.isADFS) {
        return idToken =>
          `${state.adfsEntryPoint}/adfs/oauth2/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}`;
      }
      return idToken =>
        `${state.adfsEntryPoint}/realms/${state.keyCloakRealmName}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}`;
    },
    adfsLogOutUrl(state, getters) {
      if (state.isADFS) {
        return `${state.adfsEntryPoint}/adfs/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`;
      }
      const currentLang = getters['locale/getLocaleName'] || 'ru';
      return `${state.adfsEntryPoint}/realms/${state.keyCloakRealmName}/protocol/openid-connect/logout?ui_locales=${currentLang}&redirect_uri=${window.location.origin}`;
    },
    isPublicUser() {
      return localStorageService.getToken() === PUBLIC_TOKEN;
    },
    getOfferedProgramById: state => id => {
      for (const item of state.offeredPrograms) {
        if (item.pcId === id) {
          return item;
        }
      }

      return null;
    },
    getWidgets(state) {
      return state.widgets;
    },
    isTest(state) {
      const isTestRole = state.user?.roles.includes('test');
      const isTestLocalStorage = localStorageService.getTestUser() !== null;
      const isQueryParams = router.currentRoute.query.test !== undefined;

      return isTestRole || isTestLocalStorage || isQueryParams;
    },
    hasRole: state => role => {
      return state.user?.roles?.includes(role);
    }
  },
  mutations: {
    [setAvatar]: (state, newAvatar) => {
      state.user.avatar = newAvatar;
    },
    [setNotificationMutationName]: (state, user) => {
      state.notifications.push(user);
    },
    [deleteAllNotification]: state => {
      state.notifications = [];
    },
    [setUser]: (state, user) => {
      state.user = user;
    },
    [clearState]: state => {
      state.user = {
        pageTree: []
      };
    },
    setConsolidateNavLinks: (state, value) => {
      state.consolidateNavLinks = value ?? [];
    },
    setLoadUser: (state, value) => {
      state.isLoadUser = value;
    },
    setDpoRole: state => {
      state.dpoRole = true;
    },
    setRepresentRole: state => {
      state.representRole = true;
    },
    setDpoNavLinks: (state, value) => {
      state.dpoNavLinks = value;
    },
    resizeMenu: (state, active) => {
      state.menuActive = active;
    },
    resizeWindow: state => {
      state.width = window.innerWidth;
    },
    setSettingsState: (state, value) => {
      state.isSettingOpen = value;
    },
    toggleSetting: state => {
      state.isSettingOpen = !state.isSettingOpen;
    },
    [SET_ADFS_ENTRY_POINT]: (state, adfsEntryPoint) => {
      state.adfsEntryPoint = adfsEntryPoint;
      const urls = ['auth-test.hse.ru', 'auth.hse.ru'];
      if (urls.some(url => adfsEntryPoint.includes(url))) {
        state.isADFS = true;
      }
      if (adfsEntryPoint.includes('saml-test')) {
        state.keyCloakRealmName = 'hse-dev';
      }
    },
    setStatusSevKedo: (state, {is_sev, is_kedo}) => {
      state.statusSevKedo.isKedo = is_kedo;
      state.statusSevKedo.isSev = is_sev;
    },
    updateUserPageTree: (state, arTreeItem = []) => {
      state.user.pageTree.push(...arTreeItem);
    },
    setOfferedPrograms: (state, arProgramOffer) => {
      state.offeredPrograms = arProgramOffer;
    },
    toggleFavoriteOfferedProgram: (state, programId) => {
      state.offeredPrograms.forEach(item => {
        if (item.pcId === programId) {
          item.pcFavorites = !item.pcFavorites;
        }
      });
    },
    toggleComparisonOfferedProgram(state, programId) {
      state.offeredPrograms.forEach(item => {
        if (item.pcId === programId) {
          item.pcComparison = !item.pcComparison;
        }
      });
    },
    setCountFavorite: (state, countFavorites) => {
      state.countFavorites = countFavorites;
    },
    setCountComparison: (state, countComparison) => {
      state.countComparison = countComparison;
    },
    setWidgets: (state, widgets) => {
      state.widgets = widgets;
    },
    deleteAvatar(state) {
      state.user.avatar = null;
    }
  },
  actions: {
    async getStatusSevKedo({commit}) {
      try {
        const {data} = await Axios.get(KEDO_GET_STATUS_SEV_KEDO);
        commit('setStatusSevKedo', data);
      } catch (error) {
        setTimeout(() => {
          setNotification({
            message: I18N.t(
              getUiItemCaption(
                'elk.kedo.status_sev_kedo.widget',
                'notAvailableServiceSevKedo'
              )
            )
          });
        });
      }
    },
    getConsolidateNavLinks: ({getters}, externalLinks = []) => {
      let result = getters.pageTreeForMenu;
      if ((getters.userDpo || getters.userRepresent) && externalLinks) {
        if (result.findIndex(item => item.type === 'dpo') < 0) {
          externalLinks.forEach(item => {
            result.push({
              ...item,
              is_in_menu: true,
              anchor_name: item.title,
              block_name: item.title,
              url: item.link,
              ui_item_type: 'Page',
              type: 'dpo'
            });
          });
        }
      }
      return result;
    },
    [getUser]: async context => {
      context.commit('setLoadUser', true);

      try {
        const {data} = await Axios.get(GET_USER_API_METHOD);

        if (
          data &&
          Array.isArray(data.pageTree) &&
          Object.prototype.hasOwnProperty.call(data.captions, 'common') &&
          Object.prototype.hasOwnProperty.call(data.captions, 'ui_item')
        ) {
          if (
            Array.isArray(data.login_block_function) &&
            data.login_block_function?.length
          ) {
            const blocingkWidgetSignature = data.login_block_function[0];

            context.commit(setUser, {
              ...data,
              pageTree: getFilteredPageTreeByBlockWidgetSignature(
                data.pageTree,
                blocingkWidgetSignature
              )
            });
          } else {
            context.commit(setUser, data);
          }

          if (data.roles.includes('postgraduate')) {
            context.dispatch(`postgraduate/${GET_LEARNERS}`);
          }

          setI18nLanguage(data.captions);

          if (data?.roles.includes('dpo')) {
            context.commit('setDpoRole');
            await context.dispatch(GET_ADDITIONAL_PAGE_TREE_ITEMS, '/main'); // TODO: проверка ролей, если требуется
          }

          if (data?.roles?.includes('represent')) {
            context.commit('setRepresentRole');

            if (data?.roles.includes('company_admin')) {
              await context.dispatch(
                GET_ADDITIONAL_PAGE_TREE_ITEMS,
                '/legal-main'
              );
            } else {
              await context.dispatch(
                GET_ADDITIONAL_PAGE_TREE_ITEMS,
                '/legal-represent'
              );
            }
          }

          context.dispatch(setNewRoutes);
          context.dispatch('set-widgets');
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        context.commit('setLoadUser', false);
      }
    },
    [GET_ADDITIONAL_PAGE_TREE_ITEMS]: async (context, slug = '') => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // Таймаут 12 секунд

      try {
        const {data} = await Axios.get(GET_ADDITIONAL_PAGE_TREE + slug, {
          signal: controller.signal
        });

        if (data.data.length) {
          let list = [];
          data.data.forEach(item => {
            if (!item.parent_id) {
              list.push({
                ...item,
                is_in_menu: true,
                is_new_menu: true,
                children: []
              });
            } else {
              const parent = list.find(parent => parent.id === item.parent_id);
              parent?.children?.push(item);
            }
          });

          const ROLES = context.state.user.roles;
          if (ROLES.length > 1 && !context.getters.userRepresent) {
            list = [
              {
                signature: 'dpo.my_menu_icon',
                image: 'dpo.my_menu_icon',
                link: '',
                title: 'Моё ДПО',
                is_in_menu: true,
                is_new_menu: true,
                children: list
              }
            ];
          }

          context.commit('setDpoNavLinks', list);
          context
            .dispatch('getConsolidateNavLinks', list)
            .then(e => context.commit('setConsolidateNavLinks', e))
            .catch(() =>
              context.commit(
                'setConsolidateNavLinks',
                context.getters.pageTreeForMenu
              )
            );
        }
      } catch (e) {
        if (e.config.signal.aborted) {
          context.commit(setNotificationMutationName, {
            message: 'Время ожидания ответа от сервера превышено.',
            type: 'error'
          });
        } else {
          context.commit(setNotificationMutationName, {
            message: e.response?.data?.message ?? 'Не удалось получить меню',
            type: 'error'
          });
        }
      } finally {
        clearTimeout(timeoutId);
      }
    },
    [GET_ADFS_ENTRY_POINT]: async context => {
      try {
        const {data} = await Axios.get(GET_ADFS_ENTRY_POINT_API_METHOD);
        if (data && data.adfs_entry_point) {
          context.commit(SET_ADFS_ENTRY_POINT, data.adfs_entry_point);
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [setNewRoutes]: context => {
      let newRoutes = [];
      context.state.user.pageTree.forEach(page => {
        let url = page.url;

        // Set unavailable widget
        const indexUnAvailableWidget = page.widgets.findIndex(
          widget => widget.signature === 'elk.unavailable.widget'
        );

        if (indexUnAvailableWidget >= 0) {
          const unAvailableWidget = page.widgets.splice(
            indexUnAvailableWidget,
            1
          )[0];

          if (!context.getters.isTest) {
            page.widgets = [unAvailableWidget];
            if (page.signature === 'elk.main') {
              context.state.mainPageNotAvailable = true;
            }
          }
        }

        if (page.ui_item_type.toLowerCase() == 'nested') {
          url = url + '*';
        } else if (page.ui_item_type.toLowerCase() == 'detail') {
          url = url + '/:id';
        }

        let newRoute = {
          path: url.startsWith('/') ? url : '/' + url,
          name: page.signature,
          component: pageLoader(page.signature),
          props: {
            name: page.block_name,
            widgets: page.widgets,
            signaturePage: page.signature
          }
        };

        /*if (page.signature.startsWith('dpo.')) {
          newRoute.beforeEnter = middleware.hasDpoRoleMiddleware;
        }*/

        newRoutes.push(newRoute);
      });

      newRoutes.push({
        path: '/*',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/404/index.vue'),
        beforeEnter: async (to, from, next) => {
          if (!context.getters.isPublicUser && to.path === REGISTRATION_ROUTE) {
            if (to.query.type) {
              try {
                await setActivityRole(to.query.type);
                if (isValidUrl(to.query.returnurl)) {
                  location.assign(to.query.returnurl);
                } else {
                  location.assign(location.origin);
                }

                return next(false);
              } catch {
                //sentry
              }
            }
            return next(HOME_ROUTE);
          }
          next();
        }
      });

      newRoutes.forEach(route => router.addRoute(route));
    },
    ['set-widgets']: context => {
      let widgetsList = new Map();

      const getWidgetData = item => {
        const {widgets, signature, ...rest} = item;

        if (signature) {
          widgetsList.set(signature, {signature, ...rest});
        }

        widgets?.forEach(widget => {
          getWidgetData(widget);
        });
      };

      context.state.user.pageTree.forEach(page => {
        getWidgetData(page);
      });

      context.commit('setWidgets', widgetsList);
    },
    getOfferedPrograms: async context => {
      try {
        const {data: response} = await Axios.get(
          GET_RECOMMENDED_PROGRAMS /* TODO: fix url, TODO: add options */
        );

        if (response.data?.length) {
          context.commit(
            'setOfferedPrograms',
            response.data?.map(prepareProgramCardRecommendedView)
          );
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },

    updateFavoriteOfferedProgramById: async (context, id) => {
      const program = context.getters.getOfferedProgramById(id);

      if (!program) return false;

      context.commit('toggleFavoriteOfferedProgram', id);
      return true;
    },
    addProgramToFavorites: async (context, {id, callback}) => {
      try {
        const {data: res} = await Axios.post(FAVORITE_PROGRAM_ADD, {
          program_id: id
        });
        context.commit('setCountFavorite', res?.data?.favorites_count);
        context.commit('programs/setCountFavorite', res?.data?.favorites_count);
        if (callback && typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },

    deleteProgramFromFavorites: async (context, {id, callback}) => {
      try {
        const {data: res} = await Axios.post(FAVORITE_PROGRAM_REMOVE, {
          program_id: id
        });
        context.commit('setCountFavorite', res?.data?.favorites_count);
        context.commit('programs/setCountFavorite', res?.data?.favorites_count);
        if (callback && typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },

    updateComparisonOfferedProgramById: async (context, id) => {
      const program = context.getters.getOfferedProgramById(id);

      if (!program) return false;

      context.commit('toggleComparisonOfferedProgram', id);
      return true;
    },

    addProgramToComparison: async (context, {id, callback}) => {
      try {
        const {data: res} = await Axios.post(COMPARISON_PROGRAM_ADD, {
          program_id: id
        });
        context.commit('setCountComparison', res?.data?.compares_count);
        if (callback && typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },

    deleteProgramFromComparison: async (context, {id, callback}) => {
      try {
        const {data: res} = await Axios.post(COMPARISON_PROGRAM_REMOVE, {
          program_id: id
        });
        context.commit('setCountComparison', res?.data?.compares_count);
        if (callback && typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    switchViewWidgetFromPage: (
      context,
      {pageSignature, widgetSignature, visible = true, switchDisable}
    ) => {
      const page = context.state.user.pageTree.find(
        page => page.signature === pageSignature
      );

      if (!page) {
        throw new Error('Page not found');
      }

      const widgetIndex = page.widgets.findIndex(
        widget => widget.signature === widgetSignature
      );

      if (widgetIndex < 0) {
        throw new Error('Widget not found');
      }
      page.widgets[widgetIndex].visible = visible;
      page.widgets[widgetIndex].switchDisable = switchDisable;
    },
    clearAvatar: context => {
      context.commit('deleteAvatar');
    },
    checkTouchDevice: context => {
      context.state.isTouchDevice =
        'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }
  },
  modules: {
    myServices: elkMyServices,
    homeServices: elkHomeService,
    myContacts: elkMyContacts,
    notificationCounter: notificationCounter,
    devConstants: developmentConstants,
    footer: elkFooter,
    breadcrumbs: elkBreadcrumbs,
    locale: elkLocale,
    postgraduate: postgraduate,
    dictionaries: dictionaries,
    validationPeriods,
    cart: cartModule,
    compares: compares,
    services: servicesModule,
    schedule: scheduleModule,
    programs: programs,
    refDepartments: refDepartments,
    refTrainingAreas: refTrainingAreas,
    refFinalDocumentTypes: refFinalDocumentTypes,
    refDurationTypes: refDurationTypes,
    refDurationHours: refDurationHours,
    refForWhom: refForWhom,
    refEduFormats: refEduFormats,
    refLanguages: refLanguages,
    refCampuses: refCampuses,
    refProgramTypes: refProgramTypes,
    profileFillCheck,
    profileData,
    refPaymentOptions: paymentOptions,
    countries: countries,
    fieldRules: fieldRules,
    representProfileLegal: representProfileLegalStore,
    representProfile: representProfileStore,
    studentService: studentService
  },
  plugins: [NotifPlugin]
});
