<template>
  <div class="part-form-c-individual part-form-contract">
    <el-form-item
      v-if="payer"
      :label="localeText.selectPayer"
      prop="payer"
    >
      <el-select
        v-model="payer"
        :value="payer"
        placeholder=""
        clearable
        filterable
        @change="$emit('changePayer', payer)"
      >
        <el-option
          v-for="item in payers"
          :key="item.id"
          :label="item.full_name"
          :value="item.id"
          widt
        />
      </el-select>
    </el-form-item>
    <el-form-item
      :label="localeText.customerFullName + '*'"
      prop="full_name"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.full_name"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.full_name.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :md="12">
        <el-form-item
          :label="localeText.customerDateBirth + '*'"
          prop="date_birth"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.date_birth"
            :errors="localeErrors"
          >
            <el-date-picker
              v-model="vuelidateData.date_birth.$model"
              type="date"
              placeholder=""
              format="dd.MM.yyyy"
              style="width: 100%"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.customerGender + '*'"
          prop="gender"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.gender"
            :errors="localeErrors"
          >
            <el-select
              v-model="vuelidateData.gender.$model"
              :value="vuelidateData.gender.$model"
            >
              <el-option
                v-for="item in genders"
                :key="item.id"
                :label="item.name"
                :value="item.code"
                widt
              />
            </el-select>
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.customerPlaceBirth + '*'"
      prop="place_birth"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.place_birth"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.place_birth.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.customerResidential + '*'"
      prop="actual_address"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.actual_address"
        :errors="localeErrors"
      >
        <el-autocomplete
          v-model="vuelidateData.actual_address.$model"
          :fetch-suggestions="debouncedSearchAddress"
          placeholder=""
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.customerRegistration + '*'"
      prop="registration_address"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.registration_address"
        :errors="localeErrors"
      >
        <el-autocomplete
          v-model="vuelidateData.registration_address.$model"
          :fetch-suggestions="debouncedSearchAddress"
          placeholder=""
        />
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :md="12">
        <el-form-item
          :label="localeText.phoneNumber + '*'"
          prop="phone"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.phone"
            :errors="localeErrors"
          >
            <InputForMask
              v-model="vuelidateData.phone.$model"
              :is-remove-masked="false"
              :value-length="10"
              mask="+7 (###) ###-##-##"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="E-mail *"
          prop="email"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.email"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.email.$model"
              type="email"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.orderTypeIdentity + '*'"
      prop="type_document"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.type_document"
        :errors="localeErrors"
      >
        <el-select
          v-model="vuelidateData.type_document.$model"
          :value="vuelidateData.type_document.$model"
        >
          <el-option
            v-for="item in docTypes"
            :key="item.id"
            :label="item.name"
            :value="item.code"
            widt
          />
        </el-select>
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :md="6">
        <el-form-item
          :label="localeText.documentSeries + '*'"
          prop="serial_number"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.serial_number"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.serial_number.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item
          :label="localeText.documentNumber + '*'"
          prop="number"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.number"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.number.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.documentWhenIssued + '*'"
          prop="dateOfIssue"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.date_issue"
            :errors="localeErrors"
          >
            <el-date-picker
              v-model="vuelidateData.date_issue.$model"
              type="date"
              placeholder=""
              format="dd.MM.yyyy"
              style="width: 100%"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.documentIssuedBy + '*'"
      prop="issuedBy"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.issued_by"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.issued_by.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.departmentCode"
      prop="code"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.code"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.code.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24" hidden>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderValidityPeriod + '*'"
          prop="validity"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.validity"
            :errors="localeErrors"
          >
            <el-date-picker
              v-model="vuelidateData.validity.$model"
              type="date"
              placeholder=""
              format="dd.MM.yyyy"
              style="width: 100%"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderDateRegistration"
          prop="dateRegistration"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.date_registration"
            :errors="localeErrors"
          >
            <el-date-picker
              v-model="vuelidateData.date_registration.$model"
              type="date"
              placeholder=""
              format="dd.MM.yyyy"
              style="width: 100%"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.contactsNationality + '*'"
      prop="citizenship"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.citizenship"
        :errors="localeErrors"
      >
        <el-select
          v-model="vuelidateData.citizenship.$model"
          :value="vuelidateData.citizenship.$model"
          filterable
        >
          <el-option
            v-for="item in countries"
            :key="item.id"
            :label="item.name"
            :value="item.code"
            widt
          />
        </el-select>
      </form-element-container>
    </el-form-item>
    <el-form-item>
      <form-element-container
        :vuelidate-data="vuelidateData.file"
        :errors="localeErrors"
      >
        <input-file
          id="id_file"
          text-align="center"
          :required="true"
          :accept-extensions="arrImageExtensions"
          :show-alert-info="true"
          :label="localeText.orderIdentityScanCopy"
          :value="vuelidateData.file"
        />
      </form-element-container>
    </el-form-item>
  </div>
</template>

<script>
import InputForMask from '@/components/FormGenerator/inputs/simple/withMaskInput/maskInput/inputForMask';
import InputFile from '@/components/inputComponent/InputFile';
import formElementContainer from '@/components/formOrder/formElementContainer';
import getDadata from '@/components/widgets/elkOlympiads/getDadata';
import {debounce} from 'throttle-debounce';
import setNotification from '@/utils/setNotification';

export default {
  name: 'PartFormContractIndividual',
  components: {InputForMask, InputFile, formElementContainer},
  props: {
    localization: {
      type: Object,
      default: () => {}
    },
    vuelidateData: {
      type: Object,
      default: () => {}
    },
    payers: {
      type: Array,
      default: () => []
    },
    countries: {
      type: Array,
      default: () => []
    },
    genders: {
      type: Array,
      default: () => []
    },
    docTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      payer: '',
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      localeErrors: {
        required: this.localization.commonCaption('errRequired'),
        email: this.localization.commonCaption('errIncorrectEmail')
      },
      localeText: {
        selectPayer: this.localization.widgetCaption('selectPayer'),
        customerFullName: this.localization.widgetCaption('customerFullName'),
        customerDateBirth: this.localization.widgetCaption('customerDateBirth'),
        customerGender: this.localization.widgetCaption('customerGender'),
        customerPlaceBirth:
          this.localization.widgetCaption('customerPlaceBirth'),
        customerResidential: this.localization.widgetCaption(
          'customerResidential'
        ),
        customerRegistration: this.localization.widgetCaption(
          'customerRegistration'
        ),
        orderTypeIdentity: this.localization.widgetCaption('orderTypeIdentity'),
        orderValidityPeriod: this.localization.widgetCaption(
          'orderValidityPeriod'
        ),
        orderDateRegistration: this.localization.widgetCaption(
          'orderDateRegistration'
        ),
        orderIdentityScanCopy: this.localization.widgetCaption(
          'orderIdentityScanCopy'
        ),
        phoneNumber: this.localization.commonCaption('phoneNumber'),
        documentSeries: this.localization.commonCaption('documentSeries'),
        documentNumber: this.localization.commonCaption('documentNumber'),
        documentWhenIssued:
          this.localization.commonCaption('documentWhenIssued'),
        documentIssuedBy: this.localization.commonCaption('documentIssuedBy'),
        departmentCode: this.localization.commonCaption('departmentCode'),
        contactsNationality: this.localization.commonCaption(
          'contacts_nationality'
        )
      }
    };
  },
  methods: {
    getFullName(first_name, last_name, patronymic) {
      let fullName = last_name ?? '';

      if (first_name) {
        fullName += ` ${first_name}`;
      }
      if (patronymic) {
        fullName += ` ${patronymic}`;
      }

      return fullName;
    },
    debouncedSearchAddress: debounce(500, async function (queryString, cb) {
      if (queryString) {
        try {
          const data = await getDadata('urlAddress', queryString);
          if (data) {
            cb(data.suggestions);
          } else {
            cb([]);
          }
        } catch (err) {
          cb([]);
          if (!err.isHandled) {
            setNotification({
              message: `Не удалось получить адреса. Попробуйте позже или обратитесь в службу поддержки`
            });
          }
        }
      } else {
        cb([]);
      }
    })
  }
};
</script>

<style lang="scss"></style>
