<template>
  <div class="part-form-c-legal part-form-contract">
    <alert-attention :text="localeText.alertVerifyPayer" />
    <el-form-item
      v-if="payer"
      :label="localeText.selectPayer"
      prop="payer"
    >
      <el-select
        v-model="payer"
        :value="payer"
        placeholder=""
        clearable
        filterable
        @change="$emit('changePayer', payer)"
      >
        <el-option
          v-for="item in payers"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          widt
        />
      </el-select>
    </el-form-item>
    <el-form-item
      :label="localeText.customerLegalName + '*'"
      prop="name"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.name"
        :errors="localeErrors"
      >
        <el-autocomplete
          v-model="computedNameModel"
          :fetch-suggestions="debounceSearchOrgName"
          placeholder=""
          @select="handleSelectOrg"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.customerFullNameLegal + '*'"
      prop="full_name"
      hidden
    >
      <form-element-container
        :vuelidate-data="vuelidateData.full_name"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.full_name.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :md="8">
        <el-form-item
          :label="localeText.orderLegalInn + '*'"
          prop="inn"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.inn"
            :errors="localeErrors"
          >
            <el-autocomplete
              v-model="vuelidateData.inn.$model"
              :fetch-suggestions="debounceSearchOrgInn"
              placeholder=""
              @select="handleSelectOrg"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          :label="localeText.orderLegalKpp + '*'"
          prop="kpp"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.kpp"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.kpp.$model"
              type="number"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          :label="localeText.orderLegalOgrn + '*'"
          prop="ogrn"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.ogrn"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.ogrn.$model"
              type="number"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.orderHeadFullName + '*'"
      prop="manager_name"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.manager_name"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.manager_name.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.fullNameSignatory + '*'"
      prop="signatory_name"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.signatory_name"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.signatory_name.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.orderSignatoryPosition + '*'"
      prop="signatory_position"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.signatory_position"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.signatory_position.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.orderActsBasis + '*'"
      prop="based"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.based"
        :errors="localeErrors"
      >
        <el-select
          v-model="vuelidateData.based.$model"
          :value="vuelidateData.based.$model"
        >
          <el-option
            v-for="item in availableBased"
            :key="item.id"
            :label="item.name"
            :value="item.code"
            widt
          />
        </el-select>
      </form-element-container>
    </el-form-item>
    <el-form-item
      v-if="vuelidateData.based.$model === 'BITVUZ.Доверенности'"
      :label="localeText.orderNotarizedAttorney + '*'"
      prop="place_registration"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.place_registration"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.place_registration.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-row
      v-if="vuelidateData.based.$model === 'BITVUZ.Доверенности'"
      :gutter="24"
    >
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderNumberAttorney + '*'"
          prop="power_attorney_num"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.power_attorney_num"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.power_attorney_num.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderDateAttorney + '*'"
          prop="power_attorney_date"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.power_attorney_date"
            :errors="localeErrors"
          >
            <el-date-picker
              v-model="vuelidateData.power_attorney_date.$model"
              type="date"
              placeholder=""
              format="dd.MM.yyyy"
              style="width: 100%"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderBicBank + '*'"
          prop="bic"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.bic"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.bic.$model"
              type="number"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderNameBank + '*'"
          prop="bank_name"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.bank_name"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.bank_name.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderCorrAccount + '*'"
          prop="correspondent_account"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.correspondent_account"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.correspondent_account.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderCheckingAccount + '*'"
          prop="checking_account"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.checking_account"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.checking_account.$model"
              type="text"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.orderActualAddress + '*'"
      prop="actual_address"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.actual_address"
        :errors="localeErrors"
      >
        <el-autocomplete
          v-model="vuelidateData.actual_address.$model"
          :fetch-suggestions="debouncedSearchAddress"
          placeholder=""
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.orderLegalAddress + '*'"
      prop="legal_address"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.legal_address"
        :errors="localeErrors"
      >
        <el-autocomplete
          v-model="vuelidateData.legal_address.$model"
          :fetch-suggestions="debouncedSearchAddress"
          placeholder=""
        />
      </form-element-container>
      <div class="inputCheckbox mt-1 mb-0">
        <input
          id="sameAsActual"
          v-model="sameAsActual"
          class="custom-checkbox"
          type="checkbox"
          name="checkbox"
          @click="handleSameAsActual"
        />
        <label
          for="sameAsActual"
          class="actual_address_label"
        >
          {{ localeText.checkboxSameAddress }}
        </label>
      </div>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :md="12">
        <el-form-item
          :label="localeText.phoneNumber + '*'"
          prop="phone"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.phone"
            :errors="localeErrors"
          >
            <input-for-mask
              v-model="vuelidateData.phone.$model"
              :is-remove-masked="false"
              :value-length="10"
              mask="+7 (###) ###-##-##"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderEmailAddress + '*'"
          prop="email"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.email"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.email.$model"
              type="email"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      :label="localeText.orderContactPerson + '*'"
      prop="contact_name"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.contact_name"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.contact_name.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-form-item
      :label="localeText.orderContactPersonPos + '*'"
      prop="contact_position"
    >
      <form-element-container
        :vuelidate-data="vuelidateData.contact_position"
        :errors="localeErrors"
      >
        <el-input
          v-model="vuelidateData.contact_position.$model"
          type="text"
        />
      </form-element-container>
    </el-form-item>
    <el-row :gutter="24">
      <alert-attention :text="localeText.alertVerifyEmailPhone" />
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderContactPersonPhone + '*'"
          prop="contact_phone"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.contact_phone"
            :errors="localeErrors"
          >
            <input-for-mask
              v-model="vuelidateData.contact_phone.$model"
              :is-remove-masked="false"
              :value-length="10"
              mask="+7 (###) ###-##-##"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="localeText.orderContactPersonEmail + '*'"
          prop="contact_mail"
        >
          <form-element-container
            :vuelidate-data="vuelidateData.contact_mail"
            :errors="localeErrors"
          >
            <el-input
              v-model="vuelidateData.contact_mail.$model"
              type="mail"
            />
          </form-element-container>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import InputForMask from '@/components/FormGenerator/inputs/simple/withMaskInput/maskInput/inputForMask';
import formElementContainer from '@/components/formOrder/formElementContainer';
import getDadata from '@/components/widgets/elkOlympiads/getDadata';
import {debounce} from 'throttle-debounce';
import setNotification from '@/utils/setNotification';
import Axios from 'axios';
import AlertAttention from '@/components/newAlerts/alertAttention.vue';

export default {
  name: 'PartFormContractLegal',
  components: {AlertAttention, InputForMask, formElementContainer},
  props: {
    localization: {
      type: Object,
      default: () => {}
    },
    vuelidateData: {
      type: Object,
      default: () => {}
    },
    availableBased: {
      type: Array,
      default: () => []
    },
    payers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sameAsActual: false,
      payer: '',
      requiredError: 'Обязательно для заполнения',
      localeErrors: {
        required: this.localization.commonCaption('errRequired'),
        email: this.localization.commonCaption('errIncorrectEmail')
      },
      localeText: {
        selectPayer: this.localization.widgetCaption('selectPayer'),
        checkboxSameAddress: this.localization.widgetCaption(
          'checkboxSameAddress'
        ),
        alertVerifyPayer: this.localization.widgetCaption('alertVerifyPayer'),
        alertVerifyEmailPhone: this.localization.widgetCaption(
          'alertVerifyEmailPhone'
        ),
        customerLegalName: this.localization.widgetCaption('customerLegalName'),
        customerFullNameLegal: this.localization.widgetCaption(
          'customerFullNameLegal'
        ),
        orderLegalInn: this.localization.widgetCaption('orderLegalInn'),
        orderLegalKpp: this.localization.widgetCaption('orderLegalKpp'),
        orderLegalOgrn: this.localization.widgetCaption('orderLegalOgrn'),
        orderHeadFullName: this.localization.widgetCaption('orderHeadFullName'),
        fullNameSignatory: this.localization.widgetCaption('fullNameSignatory'),
        orderSignatoryPosition: this.localization.widgetCaption(
          'orderSignatoryPosition'
        ),
        orderActsBasis: this.localization.widgetCaption('orderActsBasis'),
        orderNotarizedAttorney: this.localization.widgetCaption(
          'orderNotarizedAttorney'
        ),
        orderNumberAttorney: this.localization.widgetCaption(
          'orderNumberAttorney'
        ),
        orderDateAttorney: this.localization.widgetCaption('orderDateAttorney'),
        orderBicBank: this.localization.widgetCaption('orderBicBank'),
        orderNameBank: this.localization.widgetCaption('orderNameBank'),
        orderCorrAccount: this.localization.widgetCaption('orderCorrAccount'),
        orderCheckingAccount: this.localization.widgetCaption(
          'orderCheckingAccount'
        ),
        orderActualAddress:
          this.localization.widgetCaption('orderActualAddress'),
        orderLegalAddress: this.localization.widgetCaption('orderLegalAddress'),
        orderContactPerson:
          this.localization.widgetCaption('orderContactPerson'),
        orderContactPersonPos: this.localization.widgetCaption(
          'orderContactPersonPos'
        ),
        orderContactPersonPhone: this.localization.widgetCaption(
          'orderContactPersonPhone'
        ),
        orderContactPersonEmail: this.localization.widgetCaption(
          'orderContactPersonEmail'
        ),
        phoneNumber: this.localization.widgetCaption('legalOrderPhoneNumber'),
        orderEmailAddress: this.localization.widgetCaption(
          'legalOrderEmailAddress'
        )
      }
    };
  },
  computed: {
    computedNameModel: {
      get() {
        return this.vuelidateData.name.$model;
      },
      set(value) {
        this.vuelidateData.name.$model = value;
        this.vuelidateData.full_name.$model = value;
      }
    }
  },
  methods: {
    handleSameAsActual() {
      if (!this.sameAsActual) this.sameAsActual = true;
      if (this.sameAsActual) {
        this.vuelidateData.legal_address.$model =
          this.vuelidateData.actual_address.$model;
      }
    },
    debouncedSearchAddress: debounce(500, async function (queryString, cb) {
      if (queryString) {
        try {
          const data = await getDadata('urlAddress', queryString);
          if (data) {
            cb(data.suggestions);
          } else {
            cb([]);
          }
        } catch (err) {
          cb([]);
          if (!err.isHandled) {
            setNotification({
              message: `Не удалось получить адреса. Попробуйте позже или обратитесь в службу поддержки`
            });
          }
        }
      } else {
        cb([]);
      }
    }),
    async suggestOrg(queryString) {
      try {
        const {data} = await Axios.post(
          'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
          {
            query: queryString
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Token ' + process.env.VUE_APP_DADATA_TOKEN
            }
          }
        );
        return data;
      } catch {
        return [];
      }
    },
    debounceSearchOrgName: debounce(500, async function (queryString, cb) {
      const data = await this.suggestOrg(queryString);
      const items = data.suggestions.map(e => ({
        ...e,
        companyName: e.value,
        value: `${e.value} [${e.data.inn}]`
      }));
      cb(items);
    }),
    debounceSearchOrgInn: debounce(500, async function (queryString, cb) {
      const data = await this.suggestOrg(queryString);
      cb(data.suggestions);
    }),
    handleSelectOrg(item) {
      if (!item) return;
      this.vuelidateData.name.$model =
        item.data?.name?.short || item.value || this.vuelidateData.name.$model;
      this.vuelidateData.full_name.$model =
        item.data?.name?.short || item.value || this.vuelidateData.name.$model;
      this.vuelidateData.inn.$model =
        item.data?.inn || this.vuelidateData.inn.$model;
      this.vuelidateData.kpp.$model =
        item.data?.kpp || this.vuelidateData.kpp.$model;
      this.vuelidateData.ogrn.$model =
        item.data?.ogrn || this.vuelidateData.ogrn.$model;
      this.vuelidateData.manager_name.$model =
        item.data?.management?.name || this.vuelidateData.manager_name.$model;
      this.vuelidateData.actual_address.$model =
        item.data?.address?.unrestricted_value ||
        this.vuelidateData.actual_address.$model;
      this.vuelidateData.legal_address.$model =
        item.data?.address?.unrestricted_value ||
        this.vuelidateData.legal_address.$model;
    }
  }
};
</script>

<style scoped>
.actual_address_label {
  color: #606266;
  font-size: 14px;
}
</style>
